<template>
  <validation-observer
    ref="studentDisciplineSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="studentDisciplineSaveModal"
      centered
      body-class="position-static"
      :title="isCreated ? 'Thêm SV nhận kỷ luật' : 'Cập nhật SV nhận kỷ luật'"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group
          label="Chương trình đào tạo/Bồi dưỡng"
          label-for="trainingSystemId"
        >
          <v-select
            v-model="targetData.trainingSystemId"
            :disabled="!isCreated"
            :options="trainingSystems"
            :reduce="option => option.value"
            :clearable=false
            @input="onTrainingSystemsChange"
          />
        </b-form-group>
        <b-form-group
          label="Khóa học"
          label-for="courseId"
        >
          <v-select
            v-model="targetData.courseId"
            :disabled="!isCreated"
            :options="courses"
            :reduce="option => option.value"
            @input="onCoursesChange"
          />
        </b-form-group>
        <b-form-group
          label="Lớp cố định"
          label-for="classId"
        >
          <v-select
            :disabled="!isCreated"
            v-model="targetData.classId"
            :options="classes"
            :reduce="option => option.value"
            placeholder="Lớp cố định"
            @input="onClassChange"
          />
        </b-form-group>
        <b-form-group label-for="studentId">
          <template v-slot:label>
            Học viên <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Học viên"
            rules="required"
          >
            <v-select
              v-model="targetData.studentId"
              :disabled="!isCreated"
              :options="studentsCourse"
              :reduce="option => option.id"
              label="name"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="disciplineId">
          <template v-slot:label>
            Hình thức kỷ luật <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Hình thức kỷ luật"
            rules="required"
          >
            <v-select
              v-model="targetData.disciplineId"
              :options="disciplines"
              :reduce="option => option.id"
              label="name"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Trạng thái"
            rules="required"
          >
            <v-select
              v-model="targetData.status"
              :options="statusOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('studentDisciplineSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BModal, BOverlay, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'

export default {
  name: 'StudentDisciplineSave',
  components: {
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    dataSource: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetData: {
        organizationId: getUser().orgId,
        classId: null,
        disciplineId: null,
        studentId: null,
        courseId: null,
        courseSemesterId: null,
        status: 1,
      },
      statusOptions: [
        { value: 1, label: 'Đang hoạt động' },
        { value: 0, label: 'Không hoạt động' },
      ],
      required,
    }
  },
  computed: {
    ...mapGetters({
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'dropdown/courses',
      classes: 'dropdown/classes',
      studentsCourse: 'student/students',
      disciplines: 'factDiscipline/dataSources',
      courseSemesters: 'dropdown/courseSemesters',
    }),
    isCreated() {
      return !this.dataSource
    },
  },

  methods: {
    ...mapMutations({
      setCourses: 'dropdown/SET_COURSES',
      setClasses: 'dropdown/SET_CLASSES',
      setStudents: 'student/SET_STUDENTS',
    }),
    ...mapActions({
      getCourseSemesters: 'dropdown/getCourseSemesters',
      createStudentDiscipline: 'studentDiscipline/createStudentDiscipline',
      updateStudentDiscipline: 'studentDiscipline/updateStudentDiscipline',
      getCourses: 'dropdown/getCourses',
      getClasses: 'dropdown/getClasses',
      getStudentsByCourse: 'student/getStudentsByCourse',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onTrainingSystemsChange(event) {
      this.setClasses([])
      this.targetData.classId = null

      await this.getCourses({
        organizationId: getUser().orgId,
        trainingSystemId: event,
      })
      this.targetData.courseId = null
    },
    async onCoursesChange(event) {
      this.setClasses([])
      this.targetData.classId = null
      this.setStudents([])
      this.targetData.studentId = null
      await Promise.all([
        this.getClasses({ courseId: event }),
        this.getCourseSemesters({
          organizationId: getUser().orgId,
          courseId: event,
        }),
      ])
      if (this.courseSemesters.length > 0) {
        this.targetData.courseSemesterId = this.courseSemesters[0].value
      }
    },
    async onClassChange(event) {
      this.setStudents([])
      await this.getStudentsByCourse({
        organizationId: getUser().orgId,
        courseId: this.targetData.courseId,
        classId: event,
        currentPage: 1,
        itemsPerPage: 100,
      })
    },
    async onShow() {
      if (!this.isCreated) {
        this.targetData.courseId = this.dataSource ? this.dataSource.courseId : null
      }
      if (!this.isCreated && this.dataSource) {
        this.targetData = { ...this.dataSource }
        await Promise.all([
          this.getClasses({
            organizationId: getUser().orgId,
            trainingSystemId: this.targetData.trainingSystemId,
            courseId: this.targetData.courseId,
          }),
          this.getStudentsByCourse({
            organizationId: getUser().orgId,
            trainingSystemId: this.targetData.trainingSystemId,
            courseId: this.targetData.courseId,
            classId: this.targetData.classId,
            currentPage: 1,
            itemsPerPage: 10000,
          }),
        ])
      }
    },
    onHide() {
      this.targetData = {
        organizationId: getUser().orgId,
        trainingSystemId: null,
        classId: null,
        policyId: null,
        studentId: null,
        courseId: null,
        status: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .studentDisciplineSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createStudentDiscipline(this.targetData)
            : await this.updateStudentDiscipline(this.targetData)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('studentDisciplineSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
